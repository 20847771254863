import React from "react"
import { graphql, Link } from "gatsby"

export default function Event(props) {
  return (
    <div className="event">
      <div className="columns is-vcentered">
        {props.image &&        
          <div className="column lessen-bottom-padding-mobile">
            <div style={{width: "100%"}}>
              <img src={props.image} alt="Event image" style={{objectPosition:"50% 33%", objectFit: "cover", width: "100%", height: "340px"}}/>
            </div>
          </div>
        }
        <div className="column remove-top-padding-mobile">

          <h3 className="title is-3 no-margin">{props.title}</h3>
          { props.date &&
            <p className="is-uppercase" style={{ letterSpacing: "2px"}}>{props.date}</p>
          }
          { props.time &&
            <p style={{ letterSpacing: "2px"}}>{props.time}</p>
          }
          { props.link &&
            <a className="button is-secondary half is-outlined transition" href={props.link}>
              <span className="icon is-small">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="22" viewBox="0 0 40 46"><path d="M13.214,25.714H9.643a1.075,1.075,0,0,1-1.071-1.071V21.071A1.075,1.075,0,0,1,9.643,20h3.571a1.075,1.075,0,0,1,1.071,1.071v3.571A1.075,1.075,0,0,1,13.214,25.714Zm9.643-1.071V21.071A1.075,1.075,0,0,0,21.786,20H18.214a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,22.857,24.643Zm8.571,0V21.071A1.075,1.075,0,0,0,30.357,20H26.786a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,31.429,24.643Zm-8.571,8.571V29.643a1.075,1.075,0,0,0-1.071-1.071H18.214a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,22.857,33.214Zm-8.571,0V29.643a1.075,1.075,0,0,0-1.071-1.071H9.643a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,14.286,33.214Zm17.143,0V29.643a1.075,1.075,0,0,0-1.071-1.071H26.786a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,31.429,33.214ZM40,10V41.429a4.287,4.287,0,0,1-4.286,4.286H4.286A4.287,4.287,0,0,1,0,41.429V10A4.287,4.287,0,0,1,4.286,5.714H8.571V1.071A1.075,1.075,0,0,1,9.643,0h3.571a1.075,1.075,0,0,1,1.071,1.071V5.714H25.714V1.071A1.075,1.075,0,0,1,26.786,0h3.571a1.075,1.075,0,0,1,1.071,1.071V5.714h4.286A4.287,4.287,0,0,1,40,10ZM35.714,40.893V14.286H4.286V40.893a.537.537,0,0,0,.536.536H35.179A.537.537,0,0,0,35.714,40.893Z"/></svg>
              </span>
              <span style={{paddingLeft: "15px"}}>View</span>
            </a>
          }
          { props.internalLink &&
            <Link to={`/${props.internalLink}`} className="button is-secondary half is-outlined transition">
              <span className="icon is-small">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="22" viewBox="0 0 40 46"><path d="M13.214,25.714H9.643a1.075,1.075,0,0,1-1.071-1.071V21.071A1.075,1.075,0,0,1,9.643,20h3.571a1.075,1.075,0,0,1,1.071,1.071v3.571A1.075,1.075,0,0,1,13.214,25.714Zm9.643-1.071V21.071A1.075,1.075,0,0,0,21.786,20H18.214a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,22.857,24.643Zm8.571,0V21.071A1.075,1.075,0,0,0,30.357,20H26.786a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,31.429,24.643Zm-8.571,8.571V29.643a1.075,1.075,0,0,0-1.071-1.071H18.214a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,22.857,33.214Zm-8.571,0V29.643a1.075,1.075,0,0,0-1.071-1.071H9.643a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,14.286,33.214Zm17.143,0V29.643a1.075,1.075,0,0,0-1.071-1.071H26.786a1.075,1.075,0,0,0-1.071,1.071v3.571a1.075,1.075,0,0,0,1.071,1.071h3.571A1.075,1.075,0,0,0,31.429,33.214ZM40,10V41.429a4.287,4.287,0,0,1-4.286,4.286H4.286A4.287,4.287,0,0,1,0,41.429V10A4.287,4.287,0,0,1,4.286,5.714H8.571V1.071A1.075,1.075,0,0,1,9.643,0h3.571a1.075,1.075,0,0,1,1.071,1.071V5.714H25.714V1.071A1.075,1.075,0,0,1,26.786,0h3.571a1.075,1.075,0,0,1,1.071,1.071V5.714h4.286A4.287,4.287,0,0,1,40,10ZM35.714,40.893V14.286H4.286V40.893a.537.537,0,0,0,.536.536H35.179A.537.537,0,0,0,35.714,40.893Z"/></svg>
              </span>
              <span style={{paddingLeft: "15px"}}>View</span>
            </Link>
          }
        </div>
      </div>
    </div>
  )
}