import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"
import Event from "../components/event"

import GiftCertificateSection from "../components/gift-certificate-section"

import workshopsImage from "../assets/classes-and-events/anne-pitman-workshops.jpg"
import badDogStudio from "../assets/classes-and-events/bad-dog-studio-logo.png"
import classImage from "../assets/classes-and-events/class-image.jpg"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark

  const bannerUrl = "/classes-and-events/classes-and-events-banner.jpg";

  const classes = frontmatter.classes;
  const dances = frontmatter.dances;
  const workshops = frontmatter.workshops;
  const otherEvents = frontmatter.otherEvents;

  return (
    <Layout title="Classes & Events" description="A detailed list of the different types of Classes and Events that Anne is involved with.">
      <Banner title={frontmatter.title} imageUrl={bannerUrl}/>
      <section className="section">
        <div className="container">
          <h2 className="title is-2 has-text-tertiary">Classes</h2>
          <div className="columns">
            <div className="column">
              <div className="content">           
                <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.classesText)}} /> 
                <div>   
                  <div style={{marginBottom:"50px"}}>          
                    <img src={classImage} alt="Anne teaching a class" />
                  </div>
                  <div>
                    <img src={badDogStudio} alt="Bad dog studio" style={{maxWidth: "180px"}} />
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              {classes == null &&
                <h3 className="title is-3">Classes will return when it's safe to, once again, gather.</h3>
              }
              {classes &&
                <div>
                  {classes.map((item, index) => (
                    <div>
                      <Event key={index} title={item.title} date={item.day} time={item.time} link={item.link} />
                    </div>
                  ))}
                </div>
              }
              <p className="small-margin">Please contact Anne for details on sessions.</p>
              <Link className="button is-secondary half transition" to="/contact">CONTACT ANNE</Link>
            </div>
          </div>
        </div>
      </section>   
      
      <section className="section has-background-tertiary-faded">
        <div className="container">
          <h2 className="title is-2 has-text-tertiary">Workshops</h2>
          <div>
            <div>
              {workshops == null &&
                <h3 className="title is-3">Workshops will return when it's safe to, once again, gather.</h3>
              }
              {workshops &&
                <div>
                  {workshops.map((item, index) => (
                    <div>
                      {item.internalEventPage == true &&
                        <div>
                          <Event image={item.image} title={item.title} date={item.date} time={item.time} internalLink={`events/${item.slug}`} />
                        </div>
                      }
                      {item.internalEventPage == false &&
                        <div>
                          <Event image={item.image} title={item.title} date={item.date} time={item.time} link={item.link} />
                        </div>
                      }
                    </div>
                  ))}
                </div>
              }
              <div style={{display: "flex"}}>
                <Link style={{margin: "auto"}} className="button auto is-secondary transition" to="/workshops">PARTNER WITH ANNE ON A WORKSHOP</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <h2 className="title is-2 has-text-tertiary">Seasonal Dances</h2>
          <div>
            <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.dancesText)}} />
            <div>
              {dances == null &&
                <h3 className="title is-3">Dances will return when it's safe to, once again, gather.</h3>
              }
              {dances &&
                <div>
                  {dances.map((item, index) => (
                    <div>
                      {item.internalEventPage == true &&
                        <div>
                          <Event image={item.image} title={item.title} date={item.date} time={item.time} internalLink={`events/${item.slug}`} />
                        </div>
                      }
                      {item.internalEventPage == false &&
                        <div>
                          <Event image={item.image} title={item.title} date={item.date} time={item.time} link={item.link} />
                        </div>
                      }
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        </div>
      </section>

      {otherEvents &&
        <section className="section has-background-secondary-faded">
          <div className="container">
            <div style={{maxWidth:"1200px", marginLeft: "0px", marginRight: "auto"}}>
              <h2 className="title is-2 has-text-tertiary">Other Events</h2>
              <div>
                {otherEvents.map((item, index) => (
                  <div>
                    <Event title={item.title} date={item.date} time={item.time} link={item.link} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      } 

      <section className="section">
        <div className="container"> 
          <div className="columns is-vcentered">
            <div className="column">
              <img src={workshopsImage} alt="Anne standing in the garden" className="pink-border" />
            </div>
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.workshopsText)}} />
            </div>
          </div>
        </div>
      </section>

      <GiftCertificateSection />                        
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        classes {title, day, time}
        dances {title, date, time, link, slug, internalEventPage, image}
        workshops {title, date, time, link, slug, internalEventPage, image}
        otherEvents {title, date, time, link}
        classesText
        dancesText
        workshopsText
      }
    }
  }
`